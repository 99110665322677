

















import Vue from "vue";
import ModalOverlay from "components/ModalOverlay.vue";

export default Vue.extend({
  components: { ModalOverlay },
  computed: {
    hasButton() {
      return !!this.$slots.buttonCaption;
    }
  }
});
