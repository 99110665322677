












import Vue from "vue";
import { layerProxy, storeProxy } from "../state";


export default Vue.extend({
  data() {
    return {
      appState: storeProxy,
    }
  },
  methods: {
      selectLayerChange() {
          if (this.appState.viewer.selectedLayer) {
              layerProxy.selectActiveLayer(this.appState.viewer.selectedLayer);
          }
      }
  }
});
