

































import Vue from "vue";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

import {storeProxy, LeaderboardTimespan} from "../state";

export default Vue.extend({
  components: {
    simplebar
  },
  data: () => {
    return {
      appState: storeProxy,
      minimized: localStorage.getItem("leaderboardVisible") === "false",
      timespan: localStorage.getItem("timespan")
    }
  },
  methods: {
    getPlace(index: number): string {
      const places: string[] = ["firstplace", "secondplace", "thirdplace"];
      if (index < places.length) {
        return " " + places[index];
      }
      return "";
    },
    getTimespan(): string {
      return LeaderboardTimespan[storeProxy.leaderboardTimespan];
    },
    getTimespanNames(): string[] {
      // from https://stackoverflow.com/questions/18111657/how-to-get-names-of-enum-entries#comment85271383_43091709
      return Object.values(LeaderboardTimespan).filter(value => typeof value === "string") as string[];
    },
    setTimespan(timespan: string) {
      localStorage.setItem("timespan", timespan);
      this.timespan = timespan;
      storeProxy.leaderboardTimespan = LeaderboardTimespan[timespan as keyof typeof LeaderboardTimespan];
      storeProxy.resetLeaderboard();
    },
    selectButton(timespan: string) {
      this.setButtonHighlighted(this.timespan, false);
      this.setTimespan(timespan);
      this.setButtonHighlighted(this.timespan, true);
    },
    setButtonHighlighted(timespan: string|null, highlighted: boolean) {
      if (!timespan) return;
      const button = <HTMLElement>document.querySelector(".nge-sidebar-button." + timespan);
      button.classList.toggle("selected", highlighted);
    }
  },
  mounted() {
    this.$root.$on("toggleLeaderboard", () => {
      this.minimized = !this.minimized;
    });
    this.selectButton(this.timespan || "Weekly");
  }
});
