var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-overlay",
    {
      staticClass: "list",
      attrs: { id: "adminPanel" },
      on: {
        hide: function($event) {
          return _vm.$emit("hide")
        }
      }
    },
    [
      _c("div", { staticClass: "adminPanelContent" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Admin Dashboard")]),
        _vm._v(" "),
        _c("div", { staticClass: "section" }, [
          _c("p", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://globalv1.flywire-daf.com/auth/api/v1/user",
                  target: "_blank"
                }
              },
              [_vm._v("User list")]
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("Rollback training edits for user ID:\n        "),
            _c("input", { attrs: { type: "text", id: "rollbackUserID" } }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "rollbackButton",
                on: {
                  click: function($event) {
                    return _vm.rollback()
                  }
                }
              },
              [_vm._v("Rollback")]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            {
              staticClass: "closeButton",
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("X")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }