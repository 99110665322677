import { render, staticRenderFns } from "./GettingStarted.vue?vue&type=template&id=e624d766&"
import script from "./GettingStarted.vue?vue&type=script&lang=ts&"
export * from "./GettingStarted.vue?vue&type=script&lang=ts&"
import style0 from "./GettingStarted.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/ng-extend/ng-extend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e624d766')) {
      api.createRecord('e624d766', component.options)
    } else {
      api.reload('e624d766', component.options)
    }
    module.hot.accept("./GettingStarted.vue?vue&type=template&id=e624d766&", function () {
      api.rerender('e624d766', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GettingStarted.vue"
export default component.exports