


















































































import Vue from "vue";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import { storeProxy } from "../state";
export default Vue.extend({
  components: {
    simplebar
  },
  data: () => {
    return {
      appState: storeProxy,
      minimized: localStorage.getItem("chatVisible") === "false",
      expanded: localStorage.getItem("leaderboardVisible") === "false"
    };
  },
  methods: {
    submitMessage() {
      const messageEl = <HTMLInputElement>(
        document.getElementById("chatMessage")
      );
      const message = messageEl.value;
      messageEl.value = "";
      if (message.trim() !== "") {
        this.appState.sendMessage(message);
      }
    },
    getTrophy(name: string): string {
      const places: string[] = ["🥇", "🥈", "🥉"];
      for (let i = 0; i < places.length; i++) {
        if (
          storeProxy.leaderboardEntries.length > i &&
          storeProxy.leaderboardEntries[i].name === name
        ) {
          return places[i];
        }
      }
      return "";
    },
    toggleMinimized() {
      if (this.expanded) {
        this.toggleExpanded();
      }
      this.minimized = !this.minimized;
      this.$root.$emit("toggleChat");
    },
    toggleExpanded() {
      if (this.minimized) {
        this.toggleMinimized();
      }
      this.expanded = !this.expanded;
      this.$root.$emit("toggleLeaderboard");
    },
    scrollToBottom() {
      const el = <HTMLElement>document.querySelector('.nge-chatbox-scroll .simplebar-content-wrapper');
      el.scrollTo(0, el.scrollHeight);
    },
    handleScroll() {
      const el = <HTMLElement>document.querySelector('.nge-chatbox-scroll .simplebar-content-wrapper');
      const scrollAtBottom = Math.ceil(el.scrollTop) + el.offsetHeight >= el.scrollHeight;
      if (scrollAtBottom) {
        this.appState.markLastMessageRead();
      }
    }
  },
  mounted() {
    const scrollEl = document.querySelector(".nge-chatbox-scroll .simplebar-content-wrapper")!;
    scrollEl.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    const scrollEl = document.querySelector(".nge-chatbox-scroll .simplebar-content-wrapper")!;
    scrollEl.removeEventListener("scroll", this.handleScroll);
  }
});
