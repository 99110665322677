var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "nge-overlay-blocker",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.$emit("hide")
        },
        mousedown: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "overlay",
        { staticClass: "modal overlay-content" },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }