


















import Vue from "vue";
import {storeProxy} from "../state";
import ModalOverlay from "components/ModalOverlay.vue";

export default Vue.extend({
  data() {
    return {
      appState: storeProxy
    }
  },
  components: { ModalOverlay },
  methods: {
    close() {
      this.$emit("hide");
    },
    rollback() {
      const userIDInput = <HTMLInputElement>document.getElementById("rollbackUserID");
      const userID = userIDInput.value;
      if (userID != "" && !isNaN(Number(userID))) {
        storeProxy.rollbackUserID(userID);
        userIDInput.value = "";
      }
    }
  }
});
