var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nge-usercard" }, [
    _c("div", { staticClass: "nge-usercard-profile" }, [
      _c("div", { staticClass: "nge-usercard-avatar" }, [
        _c("img", {
          staticClass: "nge-usercard-avatar-image",
          style: _vm.cssVars,
          attrs: { src: "images/icon-filled.png", width: "50" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "nge-usercard-info" }, [
        _c("div", { staticClass: "nge-usercard-name" }, [
          _vm._v(_vm._s(_vm.appState.loggedInUser.name))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "nge-usercard-email" }, [
          _vm._v(_vm._s(_vm.appState.loggedInUser.email))
        ]),
        _vm._v(" "),
        _vm.appState.loggedInUser.joinDate !== "6/30/2020"
          ? _c("div", { staticClass: "nge-usercard-date" }, [
              _vm._v("joined " + _vm._s(_vm.appState.loggedInUser.joinDate))
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "nge-usercard-edits" }, [
      _c("div", { staticClass: "nge-usercard-edits-title" }, [
        _vm._v("Cells Completed")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "nge-usercard-edits-table" }, [
        _c("div", { staticClass: "nge-usercard-edits-section" }, [
          _c("div", { staticClass: "nge-usercard-edits-count" }, [
            _vm._v(_vm._s(_vm.appState.cellsSubmitted))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.appState.userInfo
      ? _c("div", { staticClass: "nge-usercard-edits" }, [
          _c("div", { staticClass: "nge-usercard-edits-title" }, [
            _vm._v("Edits in Production")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "nge-usercard-edits-table" }, [
            _c("div", { staticClass: "nge-usercard-edits-section" }, [
              _c("div", { staticClass: "nge-usercard-edits-timespan" }, [
                _vm._v("Today")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "nge-usercard-edits-count" }, [
                _vm._v(_vm._s(_vm.appState.userInfo.editsToday))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nge-usercard-edits-section" }, [
              _c("div", { staticClass: "nge-usercard-edits-timespan" }, [
                _vm._v("Past 7 Days")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "nge-usercard-edits-count" }, [
                _vm._v(_vm._s(_vm.appState.userInfo.editsThisWeek))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nge-usercard-edits-section" }, [
              _c("div", { staticClass: "nge-usercard-edits-timespan" }, [
                _vm._v("All Time")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "nge-usercard-edits-count" }, [
                _vm._v(_vm._s(_vm.appState.userInfo.editsAllTime))
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "nge-usercard-controls" }, [
      _c(
        "button",
        {
          staticClass: "nge-usercard-logout",
          on: { click: _vm.appState.logout }
        },
        [_vm._v("Log Out")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }