var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.minimized,
          expression: "!minimized"
        }
      ],
      staticClass: "nge-leaderboard"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nge-leaderboard-content" },
        [
          _c(
            "div",
            { staticClass: "nge-leaderboard-timeselect" },
            [
              _c("div", { staticClass: "nge-leaderboard-timeselect-filler" }),
              _vm._v(" "),
              _vm._l(_vm.getTimespanNames(), function(timespan) {
                return _c(
                  "button",
                  {
                    key: timespan,
                    class: "nge-sidebar-button " + timespan,
                    attrs: {
                      title:
                        "Switch to " + timespan.toLowerCase() + " leaderboard"
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectButton(timespan)
                      }
                    }
                  },
                  [_vm._v(_vm._s(timespan))]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "nge-leaderboard-timeselect-filler" })
            ],
            2
          ),
          _vm._v(" "),
          _c("simplebar", { attrs: { "data-simplebar-auto-hide": "false" } }, [
            _c(
              "div",
              { staticClass: "nge-leaderboard-entries" },
              [
                _c(
                  "div",
                  { staticClass: "nge-leaderboard-row nge-leaderboard-header" },
                  [
                    _c("div", [_vm._v("Rank")]),
                    _vm._v(" "),
                    _c("div", [_vm._v("Name")]),
                    _vm._v(" "),
                    _c("div", [_vm._v("Edits")])
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.appState.leaderboardEntries, function(entry, index) {
                  return _c(
                    "div",
                    {
                      key: "entry" + index,
                      class:
                        "nge-leaderboard-row row" +
                        ((index + 1) % 2 ? "Odd" : "Even") +
                        _vm.getPlace(index)
                    },
                    [
                      _c("div", { staticClass: "nge-leaderboard-rank" }, [
                        _vm._v(_vm._s(index + 1))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "nge-leaderboard-name" }, [
                        _vm._v(_vm._s(entry.name))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "nge-leaderboard-score" }, [
                        _vm._v(_vm._s(entry.score))
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.appState.leaderboardLoaded,
                  expression: "!appState.leaderboardLoaded"
                }
              ],
              staticClass: "nge-leaderboard-loading"
            },
            [_vm._v("Loading...")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.appState.leaderboardLoaded &&
                    _vm.appState.leaderboardEntries.length === 0,
                  expression:
                    "appState.leaderboardLoaded && appState.leaderboardEntries.length === 0"
                }
              ],
              staticClass: "nge-leaderboard-loading"
            },
            [_vm._v("No edits yet... why not make one?")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-leaderboard-titlebar" }, [
      _c("div", { staticClass: "nge-sidebar-section-title" }, [
        _vm._v("Top Editors")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }