










































import Vue from "vue";
import ModalOverlay from "components/ModalOverlay.vue";
import ResetConfirmDialog from "components/ResetConfirmDialog.vue";
import AdminPanel from "components/AdminPanel.vue"
import {storeProxy} from "../state";
import ImageOverlay from "./ImageOverlay.vue";

export default Vue.extend({
  data() {
    return {
      appState: storeProxy
    }
  },
  components: { ResetConfirmDialog, ModalOverlay, AdminPanel, ImageOverlay }
});
