








import Vue from "vue";

import Overlay from "components/Overlay.vue";

export default Vue.extend({
  components: { Overlay }
});
