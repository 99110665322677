/**
 * @license
 * Copyright 2021 The Neuroglancer Authors
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Overlay} from 'neuroglancer/overlay';

export class Loader extends Overlay {
  constructor() {
    super();

    const existingDialog = document.querySelector('.nge-loader');
    if (existingDialog) {
      return;
    }

    let {content} = this;
    content.classList.add('loader-overlay');
    const spin = document.createElement('div');
    spin.classList.add('nge-loader');

    content.appendChild(spin);
    // spin.onblur = () => this.dispose();
    spin.focus();
  }
}