var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "vueMain" } },
    [
      _c("introduction"),
      _vm._v(" "),
      _c("extension-bar", { staticClass: "ng-extend" }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "content" } },
        [
          _c("sidebar", { staticClass: "ng-extend" }),
          _vm._v(" "),
          _c("n-g-sidebar-header", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.appState.viewer.sidebar.open,
                expression: "appState.viewer.sidebar.open"
              }
            ],
            staticClass: "ng-extend"
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("overlay-container", { staticClass: "ng-extend" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "neuroglancer-container" } }, [
      _c("div", { attrs: { id: "neuroglancer-error" } }, [
        _vm._v(
          "Oops! There was an error and Neuroglancer could not be loaded. Please follow the\n        instructions in the "
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://docs.google.com/document/d/1FvEtMnAHenufDtCqKdi2DAyPyX9IzWkW9P2H31WxkMo"
            }
          },
          [_vm._v("FAQ")]
        ),
        _vm._v(" to\n        troubleshoot.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }