var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nge-stopwatch" }, [
    _c("img", {
      staticClass: "nge-stopwatch-icon",
      attrs: { src: "images/stopwatch.svg", width: "20", title: "Stopwatch" }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "nge-stopwatch-time" }, [
      _vm._v(_vm._s(_vm.getTime()))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "nge-stopwatch-controls" }, [
      _c(
        "button",
        {
          staticClass: "nge-stopwatch-button",
          on: {
            click: function($event) {
              return _vm.toggleRunning()
            }
          }
        },
        [_vm._v(_vm._s(_vm.running ? "Stop" : "Start"))]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "nge-stopwatch-button",
          on: {
            click: function($event) {
              return _vm.reset()
            }
          }
        },
        [_vm._v("Reset")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }