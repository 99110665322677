var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropdownList",
      class: { open: _vm.isActive },
      style: { width: _vm.width, "min-width": _vm.width },
      on: {
        mousedown: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
        }
      }
    },
    [
      _vm.type === "chooser"
        ? [
            _c(
              "div",
              {
                staticClass: "dropdownChooser",
                attrs: { title: _vm.hover },
                on: { click: _vm.toggleVisible }
              },
              [
                _c("div", { staticClass: "dropdownChooserLabel" }, [
                  _c(
                    "div",
                    { staticClass: "dropdownChooserTitle" },
                    [_vm._t("chooserTitle")],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "dropdownChooserArrow" })
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "visible",
                    rawName: "v-visible",
                    value: _vm.isActive,
                    expression: "isActive"
                  }
                ],
                staticClass: "dropdownMenu chooser"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "dropdownChooserLabel",
                    on: { click: _vm.toggleVisible }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "dropdownChooserTitle" },
                      [_vm._t("chooserTitle")],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "dropdownChooserArrow" })
                  ]
                ),
                _vm._v(" "),
                _vm._t("listItems")
              ],
              2
            )
          ]
        : [
            _c(
              "button",
              { attrs: { title: _vm.hover }, on: { click: _vm.toggleVisible } },
              [_vm._t("buttonTitle")],
              2
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "visible",
                    rawName: "v-visible",
                    value: _vm.isActive,
                    expression: "isActive"
                  }
                ],
                staticClass: "dropdownMenu"
              },
              [_vm._t("listItems")],
              2
            ),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "visible",
                  rawName: "v-visible",
                  value: _vm.isActive,
                  expression: "isActive"
                }
              ],
              staticClass: "dropdownArrow"
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }