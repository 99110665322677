var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overlays" },
    [
      _vm.appState.showDatasetChooser
        ? _c("dataset-chooser", {
            on: {
              hide: function($event) {
                _vm.appState.showDatasetChooser = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.appState.showResetConfirm
        ? _c("reset-confirm-dialog", {
            on: {
              hide: function($event) {
                _vm.appState.showResetConfirm = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.appState.showAdminPanel
        ? _c("admin-panel", {
            on: {
              hide: function($event) {
                _vm.appState.showAdminPanel = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.appState.checkingOutNeuron
        ? _c("image-overlay", {
            scopedSlots: _vm._u(
              [
                {
                  key: "image",
                  fn: function() {
                    return [
                      _c("img", {
                        staticStyle: { top: "-150px" },
                        attrs: { src: "images/inspecting.png" }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "text",
                  fn: function() {
                    return [
                      _c("div", [
                        _vm._v("Checking out neuron from Proofreading Drive.")
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v("Please wait...")])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1476600002
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.appState.showSubmittedCongrats
        ? _c("image-overlay", {
            on: {
              hide: function($event) {
                _vm.appState.showSubmittedCongrats = false
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "image",
                  fn: function() {
                    return [
                      _c("img", {
                        staticStyle: { left: "-100px", top: "-150px" },
                        attrs: { src: "images/thumbsup.png" }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "text",
                  fn: function() {
                    return [
                      _c("div", [_vm._v("Congratulations!")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "Your cell has been marked as complete. Thank you for contributing to the "
                        ),
                        _c("em", [_vm._v("Drosophila")]),
                        _vm._v(" connectome.")
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "buttonCaption",
                  fn: function() {
                    return [_vm._v("ONWARD")]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1845702517
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.appState.showCheckoutHelp
        ? _c("modal-overlay", [
            _c("div", [
              _vm._v(
                '"Get Cell to Proofread" moves you to a cell that needs proofreading, and deselects other cells.'
              ),
              _c("br"),
              _vm._v(
                "\n      Our Self-Guided Training provides proofreading instructions."
              ),
              _c("br"),
              _vm._v(
                "\n      Use the Lightbulb menu to mark cell when complete."
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "nge_segment",
                  on: {
                    click: function($event) {
                      return _vm.appState.setShowCheckoutHelp(false)
                    }
                  }
                },
                [_vm._v("OK")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.appState.loggedInUser === null
        ? _c("modal-overlay", [
            _c("div", [
              _vm._v(
                "You are not logged in. Refresh the page if you do not see a Google login pop-up."
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }