






























import Vue from "vue";
import { storeProxy } from "../state";
import { Step, isNextToElementPostition } from "../introduction-steps";

interface ComputedStep {
  first: boolean,
  last: boolean,
  title?: string,
  text?: string,
  html?: string,
  video?: string,
  left: string,
  top: string,
  cssClass?: string,
  modal: boolean,
  videoCache?: HTMLVideoElement,
}

export default Vue.extend({
  props: {
    step: Object as () => Step,
    first: Boolean,
    last: Boolean,
    index: Number,
  },
  data: () => {
    return {
      appState: storeProxy,
      chipBounds: {top: 'auto', left: 'auto', 'width': 'inherit'},
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      videoReady: false,
      computedStep: {} as ComputedStep,
      inExitConfirm: false,
    }
  },
  created() {
    this.updateChipPosition();
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.chipBounds = {top: 'auto', left: 'auto', 'width': 'auto'};
      this.updateChipPosition();
    });
  },
  methods: {
    updateChipPosition() {
      const step = this.step;

      let left = '';
      let top = '';
      let cssClass = undefined;

      const element = document.querySelector(step.position.element);
      if (element) {
        const rect = element.getBoundingClientRect();

        if (isNextToElementPostition(step.position)) {
          cssClass = step.position.side;

          const {x: xOff, y: yOff} = step.position.offset || {x: 0, y: 0};

          if (step.position.side === 'right') {
            left = `${rect.right + xOff}px`;
            top = `${rect.top + rect.height / 2 + yOff}px`;
          } else if (step.position.side === 'left') {
            left = `${rect.left + xOff}px`;
            top = `${rect.top + rect.height / 2 + yOff}px`;
          } else if (step.position.side === 'bottom') {
            left = `${rect.left + rect.width / 2 + xOff}px`;
            top = `${rect.bottom + yOff}px`;
          }
        } else {
          cssClass = 'center';
          left = `${step.position.x * rect.width + rect.left}px`;
          top = `${step.position.y * rect.height + rect.top}px`;
        }
      }

      this.chipBounds = {top: 'auto', left: 'auto', 'width': 'inherit'};

      if (!step.modal) {
        this.chipBounds['width'] = '350px';
      }

      this.computedStep = {
        first: this.first,
        last: this.last,
        title: step.title,
        video: step.video,
        text: step.text,
        html: step.html,
        left,
        top,
        cssClass,
        modal: step.modal || false,
      }

      this.$nextTick(function () {
        const el = this.$el.querySelector('.chip');
        if (el) {
          const rect = el.getBoundingClientRect();

          function clamp(val: number, min: number, max: number) {
            return Math.max(min, Math.min(max, val));
          }

          function clampWidth(val: number) {
            const buffer = rect.width / 2 - (12 + 10); // half triangle width + border radius
            return clamp(val, -buffer, buffer);
          }

          function clampHeight(val: number) {
            const buffer = rect.height / 2 - (12 + 10); // half triangle width + border radius
            return clamp(val, -buffer, buffer);
          }

          if (rect.top < 0) {
            this.chipBounds.top = `${-rect.top}px`;
          }
          if (rect.left < 0) {
            this.chipBounds.left = `${-rect.left}px`;
          }
          if (rect.right > window.innerWidth) {
            this.chipBounds.left = `${clampWidth(window.innerWidth - rect.right)}px`;
          }
          if (rect.bottom > window.innerHeight) {
            this.chipBounds.top = `${clampHeight(window.innerHeight - rect.bottom)}px`;
          }
        }
      });
    },
    next() {
      this.$emit('next');
    },
    back() {
      this.$emit('back');
    },
    exitIntro() {
      this.$emit('exitIntro');
    }
  },
});
