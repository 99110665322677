var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("image-overlay", {
    attrs: { id: "resetConfirmDialog" },
    scopedSlots: _vm._u([
      {
        key: "image",
        fn: function() {
          return [
            _c("img", {
              staticStyle: { width: "95%", height: "auto" },
              attrs: { src: "images/thinkingnurro.png" }
            })
          ]
        },
        proxy: true
      },
      {
        key: "text",
        fn: function() {
          return [
            _c("div", { staticClass: "title" }, [_vm._v("Reset FlyWire View")]),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _c("p", [
                _vm._v(
                  "This will delete any cells you have selected and reset your view to the default neurons."
                )
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Are you sure you want to do this?")])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "buttons",
        fn: function() {
          return [
            _c("div", { staticClass: "actions" }, [
              _c(
                "button",
                {
                  staticClass: "cancelButton",
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "confirmButton",
                  on: {
                    click: function($event) {
                      return _vm.confirm()
                    }
                  }
                },
                [_vm._v("Reset")]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }