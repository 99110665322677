var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "introductionStep",
      class: { hasVideo: _vm.computedStep.video !== undefined }
    },
    [
      _vm.computedStep.modal
        ? _c("div", {
            staticClass: "nge-overlay-blocker",
            on: {
              mousedown: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ng-extend introductionStepAnchor",
          class: _vm.computedStep.cssClass,
          style: { left: _vm.computedStep.left, top: _vm.computedStep.top }
        },
        [
          _c("div", { staticClass: "arrow" }),
          _vm._v(" "),
          !_vm.inExitConfirm
            ? _c(
                "div",
                {
                  staticClass: "chip",
                  class: { modal: _vm.computedStep.modal },
                  style: _vm.chipBounds
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "exit",
                      on: {
                        click: function($event) {
                          _vm.inExitConfirm = true
                        }
                      }
                    },
                    [_vm._v("×")]
                  ),
                  _vm._v(" "),
                  _vm.computedStep.title
                    ? _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.computedStep.title))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.computedStep.video
                    ? _c("video", {
                        attrs: {
                          width: "350",
                          height: "242.81",
                          autoplay: "",
                          loop: "",
                          muted: "",
                          playsinline: "",
                          src: _vm.computedStep.video
                        },
                        domProps: { muted: true }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.computedStep.text
                    ? _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.computedStep.text))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.computedStep.html
                    ? _c("div", {
                        staticClass: "html",
                        domProps: { innerHTML: _vm._s(_vm.computedStep.html) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttonContainer" }, [
                    !_vm.computedStep.first
                      ? _c(
                          "button",
                          { staticClass: "back", on: { click: _vm.back } },
                          [_vm._v("back")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.computedStep.last
                      ? _c(
                          "button",
                          { staticClass: "next", on: { click: _vm.next } },
                          [_vm._v("done")]
                        )
                      : _c(
                          "button",
                          { staticClass: "next", on: { click: _vm.next } },
                          [_vm._v("next")]
                        )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.inExitConfirm
            ? _c(
                "div",
                {
                  staticClass: "chip exitConfirm",
                  class: { modal: _vm.computedStep.modal },
                  style: _vm.chipBounds
                },
                [
                  _c("div", [_vm._v("Do you want to exit the tutorial?")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttonContainer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "next",
                        on: {
                          click: function($event) {
                            _vm.inExitConfirm = false
                          }
                        }
                      },
                      [_vm._v("No")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      { staticClass: "next", on: { click: _vm.exitIntro } },
                      [_vm._v("Yes")]
                    )
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }