





















import Vue from "vue";
import {storeProxy} from "../state";
import ImageOverlay from "./ImageOverlay.vue";

export default Vue.extend({
  data() {
    return {
      appState: storeProxy
    }
  },
  components: { ImageOverlay },
  methods: {
    cancel() {
      this.$emit("hide");
    },
    confirm() {
      this.$root.$emit("confirmReset");
      this.$emit("hide");
    }
  }
});
