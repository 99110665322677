var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "nge-sidebar" + (_vm.visible ? " visible" : "") },
    [_c("getting-started"), _vm._v(" "), _c("div"), _vm._v(" "), _c("chatbox")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }