var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nge-chatbox", attrs: { tabindex: "1" } }, [
    _c("div", { staticClass: "nge-chatbox-title" }, [
      _c("div", [_vm._v("Chat")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "nge-chatbox-title-button",
          on: {
            click: function($event) {
              return _vm.toggleMinimized()
            }
          }
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.minimized,
                expression: "!minimized"
              }
            ],
            attrs: {
              src: "images/minimize.svg",
              width: "15",
              title: "Minimize"
            }
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.minimized,
                expression: "minimized"
              }
            ],
            attrs: { src: "images/chevron.svg", width: "15", title: "Restore" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "nge-chatbox-title-button",
          on: {
            click: function($event) {
              return _vm.toggleExpanded()
            }
          }
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.expanded,
                expression: "!expanded"
              }
            ],
            attrs: { src: "images/expand.svg", width: "15", title: "Expand" }
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.expanded,
                expression: "expanded"
              }
            ],
            staticStyle: { transform: "rotate(180deg)" },
            attrs: { src: "images/chevron.svg", width: "15", title: "Restore" }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.minimized,
            expression: "!minimized"
          }
        ],
        staticClass: "nge-chatbox-content"
      },
      [
        _c("div", { staticClass: "nge-chatbox-filler" }),
        _vm._v(" "),
        _c(
          "simplebar",
          {
            staticClass: "nge-chatbox-scroll",
            attrs: { "data-simplebar-auto-hide": "false" }
          },
          [
            _c(
              "div",
              { staticClass: "nge-chatbox-messages" },
              _vm._l(_vm.appState.chatMessages, function(message, index) {
                return _c(
                  "span",
                  { key: "message" + index, staticClass: "nge-chatbox-item" },
                  [
                    message.type === "users"
                      ? _c("div", { staticClass: "nge-chatbox-info" }, [
                          _c(
                            "div",
                            { staticClass: "nge-chatbox-info-content" },
                            [_vm._v(_vm._s(message.name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "nge-chatbox-info-content" },
                            [_vm._v("Type !help to see available commands.")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    message.type === "join"
                      ? _c("div", { staticClass: "nge-chatbox-info" }, [
                          _c(
                            "div",
                            { staticClass: "nge-chatbox-info-content" },
                            [_vm._v(_vm._s(message.name) + " joined chat.")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    message.type === "leave"
                      ? _c("div", { staticClass: "nge-chatbox-info" }, [
                          _c(
                            "div",
                            { staticClass: "nge-chatbox-info-content" },
                            [_vm._v(_vm._s(message.name) + " left chat.")]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    message.type === "disconnected"
                      ? _c("div", { staticClass: "nge-chatbox-info" }, [
                          _c(
                            "div",
                            { staticClass: "nge-chatbox-info-content" },
                            [
                              _vm._v(
                                "Your message was not sent because you were disconnected from chat. Try reloading the page."
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    message.type === "time"
                      ? _c("div", { staticClass: "nge-chatbox-time" }, [
                          _vm._v(_vm._s(message.time))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    message.type === "message"
                      ? _c(
                          "div",
                          {
                            staticClass: "nge-chatbox-message",
                            attrs: { title: message.time }
                          },
                          _vm._l(message.parts, function(part, partIndex) {
                            return _c(
                              "span",
                              { key: "messagepart" + index + "-" + partIndex },
                              [
                                part.type === "sender"
                                  ? _c(
                                      "span",
                                      {
                                        class:
                                          "nge-chatbox-message-text sender " +
                                          message.rank
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(part.text) +
                                            _vm._s(
                                              _vm.getTrophy(message.name)
                                            ) +
                                            ":"
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                part.type === "text"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "nge-chatbox-message-text"
                                      },
                                      [_vm._v(_vm._s(part.text))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                part.type === "link"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nge-chatbox-message-text",
                                        attrs: {
                                          target: "_blank",
                                          href: part.text
                                        }
                                      },
                                      [_vm._v(_vm._s(part.text))]
                                    )
                                  : _vm._e()
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.appState.unreadMessages,
                expression: "appState.unreadMessages"
              }
            ],
            staticClass: "nge-chatbox-unread",
            on: {
              click: function($event) {
                return _vm.scrollToBottom()
              }
            }
          },
          [_vm._v("NEW MESSAGES")]
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "nge-chatbox-sendmessage",
            attrs: { autocomplete: "off" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submitMessage($event)
              }
            }
          },
          [_vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _vm._m(2)]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-chatbox-messageprompt" }, [
      _c("img", {
        staticStyle: { transform: "rotate(90deg)" },
        attrs: { src: "images/chevron.svg", width: "15" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-chatbox-inputbox" }, [
      _c("input", { attrs: { type: "text", id: "chatMessage" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("button", { attrs: { type: "submit" } }, [
        _c("img", { attrs: { src: "images/send.svg", width: "15" } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }