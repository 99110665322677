


















import Vue from "vue";
import ExtensionBar from "components/ExtensionBar.vue";
import OverlayContainer from "components/OverlayContainer.vue";
import Sidebar from "components/Sidebar.vue";
import NGSidebarHeader from "components/NGSidebarHeader.vue";
import Introduction from "components/Introduction.vue";
import {storeProxy} from "../state";

export default Vue.extend({
  components: { ExtensionBar, OverlayContainer, Sidebar, NGSidebarHeader, Introduction },
  data: () => {
    return {
      appState: storeProxy,
    }
  }
});
