











import Vue from "vue";

import { storeProxy } from "../state";

export default Vue.extend({
  data: () => {
    return {
      appState: storeProxy,
      running: false,
      secondsElapsed: 0,
      timeout: -1
    };
  },
  methods: {
    toggleRunning() {
      this.running = !this.running;
      if (this.running) {
        this.timeout = setTimeout(this.updateTime, 1000);
      } else {
        clearTimeout(this.timeout);
      }
    },
    reset() {
      this.secondsElapsed = 0;
      if (this.running) {
        this.toggleRunning();
      }
    },
    getTime(): string {
      const seconds = this.secondsElapsed % 60;
      const minutesElapsed = Math.floor(this.secondsElapsed / 60);
      const minutes = minutesElapsed % 60;
      const hoursElapsed = Math.floor(minutesElapsed / 60);
      const hours = hoursElapsed;
      return (
        (hours > 0 ? hours + ":" : "") +
        this.formatTime(minutes) +
        ":" +
        this.formatTime(seconds)
      );
    },
    formatTime(t: number): string {
      if (t < 10) {
        return "0" + t;
      }
      return t.toString();
    },
    updateTime() {
      this.secondsElapsed++;
      this.timeout = setTimeout(this.updateTime, 1000);
    }
  }
});
