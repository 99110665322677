var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-overlay",
    {
      staticClass: "list",
      on: {
        hide: function($event) {
          return _vm.$emit("hide")
        }
      }
    },
    [
      _c("div", { staticClass: "dialogContent" }, [
        _c("div", { staticClass: "image" }, [_vm._t("image")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "textholder" }, [
          _c(
            "div",
            { staticClass: "text" },
            [
              _vm._t("text"),
              _vm._v(" "),
              _vm.hasButton
                ? _c(
                    "button",
                    {
                      staticClass: "imgCloseButton",
                      on: {
                        click: function($event) {
                          return _vm.$emit("hide")
                        }
                      }
                    },
                    [_vm._t("buttonCaption")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("buttons")
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }