










import Vue from "vue";

import { storeProxy } from "../state";
import Leaderboard from "components/Leaderboard.vue";
import Chatbox from "components/Chatbox.vue";
import GettingStarted from "components/GettingStarted.vue";

export default Vue.extend({
  components: { Leaderboard, Chatbox, GettingStarted },
  data: () => {
    return {
      appState: storeProxy,
      visible: localStorage.getItem("visible") !== "false",
      showChat: localStorage.getItem("chatVisible") !== "false"
    };
  },
  methods: {
    setVisible(visible: boolean) {
      localStorage.setItem("visible", visible.toString());
      this.visible = visible;
      (<HTMLElement>document.querySelector(".nge-sidebar")).classList.toggle("visible", visible);
      this.shiftStatusBars();
      if (visible) {
        const el = <HTMLElement>document.querySelector('.nge-chatbox-scroll .simplebar-content-wrapper');
        const scrollAtBottom = el.scrollTop + el.offsetHeight >= el.scrollHeight;
        if (scrollAtBottom) {
          this.appState.markLastMessageRead();
        }
      }
    },
    setChatVisible(visible: boolean) {
      localStorage.setItem("chatVisible", visible.toString());
      this.showChat = visible;
    },
    getSidebarItems(): string {
      if (this.showChat) {
        return "both";
      }
      return "lb-only";
    },
    shiftStatusBars() {
      const oldStyle = document.head.querySelector("#shiftStatusBars");
      if (oldStyle) {
        oldStyle.remove();
      }
      if (this.visible) {
        const styleSheet = document.createElement("style");
        styleSheet.id = "shiftStatusBars";
        styleSheet.innerText = "#statusContainer { left: 200px; }";
        document.head.appendChild(styleSheet);
      }
    }
  },
  mounted() {
    this.shiftStatusBars();
    this.$root.$on("toggleSidebar", () => {
      this.setVisible(!this.visible);
    });
    this.$root.$on("toggleChat", () => {
      this.setChatVisible(!this.showChat);
    });
  }
});
