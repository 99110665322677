var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ngSidebarHeader",
      style: { width: _vm.appState.viewer.sidebar.width + "px" },
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          $event.stopPropagation()
          $event.preventDefault()
        },
        mousedown: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          $event.stopPropagation()
          $event.preventDefault()
        }
      }
    },
    [
      _c("div", { staticClass: "title" }, [
        _c("div", [_vm._v("Layer Controls")]),
        _c(
          "button",
          {
            staticClass: "close",
            on: {
              click: function($event) {
                return _vm.appState.toggleSidePanel(false)
              }
            }
          },
          [_vm._v("×")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "selectContainer" }, [
        _c("label", { attrs: { for: "selectLayer" } }, [_vm._v("Layer:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.appState.viewer.selectedLayer,
                expression: "appState.viewer.selectedLayer"
              }
            ],
            attrs: { id: "selectLayer" },
            on: {
              change: [
                function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.appState.viewer,
                    "selectedLayer",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
                _vm.selectLayerChange
              ]
            }
          },
          _vm._l(_vm.appState.viewer.layers, function(layer, index) {
            return _c("option", { key: layer, domProps: { value: layer } }, [
              _vm._v(_vm._s(index + 1) + " - " + _vm._s(layer))
            ])
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }