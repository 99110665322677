



























































































































import Vue from "vue";
import { storeProxy, layerProxy, viewer, ActionsMenuItem } from "../state";
import { ThemesDialog } from "../themes/themes";
import { config } from '../main';

import DropdownList from "components/DropdownList.vue";
import Stopwatch from "components/Stopwatch.vue";
import UserCard from "components/UserCard.vue";
import { ImageLayerDescription, SegmentationLayerDescription, CellDescription } from "src/config";
import { getLayerByName } from "../layer-state";
import { SegmentationUserLayer } from "neuroglancer/segmentation_user_layer";

export default Vue.extend({
  components: { DropdownList, Stopwatch, UserCard },
  data() {
    return {
      appState: storeProxy,
      layerState: layerProxy,
      showSidebar: localStorage.getItem("visible") !== "false"
    };
  },
  computed: {
    cells() {
      return (layerProxy.activeSegmentationLayer && layerProxy.activeSegmentationLayer.curatedCells) || [];
    },
    activeCells() {
      return layerProxy.activeCells;
    },
    imageLayers() {
      return config.imageLayers;
    },
    segmentationLayers() {
      return config.segmentationLayers;
    },
    activeImageLayer() {
      return layerProxy.activeImageLayer;
    },
    activeSegmentationLayer() {
      return layerProxy.activeSegmentationLayer;
    }
  },
  methods: {
    // toggleNeuroglancerUI() {
    //   if (viewer) {
    //     viewer.uiConfiguration.showUIControls.toggle();
    //   }
    // },
    selectCell: async function(cell: CellDescription) {
      const success = await layerProxy.selectCell(cell);

      if (success) {
        // this.$emit('hide');
      } else {
        console.warn("cannot select cell because viewer is not yet created");
      }
    },
    toggleSidebar() {
      this.$root.$emit("toggleSidebar");
      this.showSidebar = !this.showSidebar;
    },
    selectImageLayer: async function(layer: ImageLayerDescription) {
      const success = await layerProxy.selectImageLayer(layer);

      if (success) {
        this.$root.$emit("closeDropdowns");
      } else {
        console.warn("cannot select layer because viewer is not yet created");
      }
    },
    selectSegmentationLayer: async function(layer: SegmentationLayerDescription) {
      const success = await layerProxy.selectSegmentationLayer(layer);

      if (success) {
        this.$root.$emit("closeDropdowns");
      } else {
        console.warn("cannot select layer because viewer is not yet created");
      }
    },
    resetSandbox() {
      this.appState.showResetConfirm = true;
    },
    showAdminPanel() {
      this.appState.showAdminPanel = true;
    },
    clickAction(item: ActionsMenuItem) {
      this.$root.$emit("closeDropdowns");
      item.click();
    },
    toggleBrainMesh() {
      layerProxy.toggleBrainMesh();
    },
    toggleBackgroundColor() {
      if (!viewer) return;
      let crossSectionColor = 0.5;
      let perspectiveViewColor = 0;
      const wasDark = viewer.perspectiveViewBackgroundColor.value[0] === 0;
      if (wasDark) {
        crossSectionColor = 1;
        perspectiveViewColor = 1;
      }
      const crossSectionBackgroundColor = viewer.crossSectionBackgroundColor.value;
      crossSectionBackgroundColor[0] = crossSectionColor;
      crossSectionBackgroundColor[1] = crossSectionColor;
      crossSectionBackgroundColor[2] = crossSectionColor;
      const perspectiveViewBackgroundColor = viewer.perspectiveViewBackgroundColor.value;
      perspectiveViewBackgroundColor[0] = perspectiveViewColor;
      perspectiveViewBackgroundColor[1] = perspectiveViewColor;
      perspectiveViewBackgroundColor[2] = perspectiveViewColor;
      const layerName = config.brainMeshURL.split("/").pop()!;
      const brainMeshLayer = getLayerByName(layerName);
      if (brainMeshLayer) {
        if (brainMeshLayer.layer instanceof SegmentationUserLayer) {
          brainMeshLayer.layer.displayState.objectAlpha.value = !wasDark ? config.brainMeshOpacityDark : config.brainMeshOpacityLight;
        }
      }
    },
    screenshot() {
      function dataURItoBlob(dataURI: string) {
        // from https://stackoverflow.com/a/12300351
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], {type: mimeString});
        return blob;
      }

      if (!viewer) return;
      const axisWasShown = viewer.showAxisLines.value;
      viewer.showAxisLines.value = false;
      viewer.display.draw();
      const screenshotData = viewer.display.canvas.toDataURL();
      const file = dataURItoBlob(screenshotData);
      // from https://stackoverflow.com/a/30832210
      const a = document.createElement("a");
      const url = URL.createObjectURL(file);
      a.href = url;
      const now = new Date(); 
      const datetime = now.getFullYear() + "-"
                      + (now.getMonth() + 1)  + "-" 
                      + now.getDate() + " "  
                      + now.getHours() + "_"  
                      + now.getMinutes() + "_" 
                      + now.getSeconds();
      a.download = "Flywire Screenshot " + datetime + ".png";
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);  
      }, 0);
      viewer.showAxisLines.value = axisWasShown;
    },
    themesMenu() {
      if (viewer) {
        new ThemesDialog(viewer, '', '', 0, 0,);
      }
    },
    openSlack() {
      window.open("https://join.slack.com/t/flywire-forum/shared_invite/zt-d6pyjonk-uC7_kjcjU~8c64t~Qid~oQ", "_blank");
    }
  },
  mounted() {
    this.$root.$on("confirmReset", () => {
      layerProxy.resetViewer();
      layerProxy.selectSegmentationLayer(config.segmentationLayers[0]);

      if (viewer) {
        viewer.layout.reset();
      }

      if (!this.showSidebar) {
        this.toggleSidebar();
      }
    });
  }
});
