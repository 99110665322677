




















































import Vue from "vue";
import { storeProxy } from "../state";

export default Vue.extend({
  data() {
    return {
      appState: storeProxy
    };
  },
  computed: {
    cssVars() {
      const name = storeProxy.loggedInUser ? storeProxy.loggedInUser.name : "";
      let nameHash = 0;
      for (let i = 0; i < name.length; i++) {
        nameHash = Math.imul(31, nameHash) + name.charCodeAt(i) | 0; // https://stackoverflow.com/a/52171480
      }
      const degrees = nameHash % 360;
      const avatarHueRotate = degrees + "deg";
      return {
        "--avatar-hue-rotate": avatarHueRotate
      };
    }
  }
});
