var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.appState.finishedLoading &&
    _vm.layerState.activeSegmentationLayer &&
    (_vm.layerState.activeSegmentationLayer.name === "Sandbox" ||
      _vm.layerState.activeSegmentationLayer.name.startsWith("Public Release"))
    ? _c(
        "div",
        { staticClass: "introduction" },
        [
          _c(
            "transition",
            { attrs: { name: "tooltip" } },
            [
              _vm.activeStep
                ? _c("introduction-step", {
                    key: _vm.activeStep.index,
                    attrs: {
                      step: _vm.activeStep.step,
                      first: _vm.activeStep.first,
                      last: _vm.activeStep.last
                    },
                    on: {
                      next: _vm.next,
                      back: _vm.back,
                      exitIntro: _vm.exitIntro
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }