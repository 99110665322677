var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nge-getting-started" }, [
    _c(
      "div",
      { staticClass: "nge-gs-links" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        !_vm.inRelease ? [_vm._m(3)] : _vm._e(),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _c("div", { staticClass: "nge-gs-link restart" }, [
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  _vm.appState.introductionStep = 0
                }
              }
            },
            [_vm._v("Restart Tutorial")]
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-gs-link welcome" }, [
      _c("a", { attrs: { href: "https://bit.ly/3s8ViUJ", target: "_blank" } }, [
        _vm._v("Welcome video")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-gs-link howtouse" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://blog.flywire.ai/2023/05/22/how-to-use-flywire-2/",
            target: "_blank"
          }
        },
        [_vm._v("How to Use FlyWire")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-gs-link quickstart" }, [
      _c("a", { attrs: { href: "https://bit.ly/3P6qXQz", target: "_blank" } }, [
        _vm._v("Quick Start")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-gs-link training" }, [
      _c("a", { attrs: { href: "https://bit.ly/3OWi6AP", target: "_blank" } }, [
        _vm._v("Self-guided training")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-gs-link cheatsheet" }, [
      _c("a", { attrs: { href: "https://bit.ly/3kzb3jx", target: "_blank" } }, [
        _vm._v("Cheatsheet")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-gs-link codex" }, [
      _c(
        "a",
        { attrs: { href: "https://codex.flywire.ai/", target: "_blank" } },
        [_vm._v("Codex")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-gs-link tools" }, [
      _c("a", { attrs: { href: "https://bit.ly/3kBLEFR", target: "_blank" } }, [
        _vm._v("Tools")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nge-gs-link support" }, [
      _c("a", { attrs: { href: "https://bit.ly/3kCoZcw", target: "_blank" } }, [
        _vm._v("Support")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }