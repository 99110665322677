var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "extensionBar" } },
    [
      _c(
        "button",
        {
          staticClass: "toggleSidebarButton",
          on: {
            click: function($event) {
              return _vm.toggleSidebar()
            }
          }
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSidebar && _vm.appState.unreadMessages,
                expression: "!showSidebar && appState.unreadMessages"
              }
            ],
            attrs: {
              src: "images/menu_notification.svg",
              width: "30",
              title: "Show sidebar"
            }
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showSidebar && !_vm.appState.unreadMessages,
                expression: "!showSidebar && !appState.unreadMessages"
              }
            ],
            attrs: {
              src: "images/menu.svg",
              width: "30",
              title: "Show sidebar"
            }
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSidebar,
                expression: "showSidebar"
              }
            ],
            attrs: {
              src: "images/menu_open.svg",
              width: "30",
              title: "Hide sidebar"
            }
          })
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "flex-fill", attrs: { id: "insertNGTopBar" } }),
      _vm._v(" "),
      _c("div", { staticClass: "ng-extend-spacer" }),
      _vm._v(" "),
      _c("div", { staticClass: "ng-extend-spacer" }),
      _vm._v(" "),
      _vm.appState.loadedViewer
        ? [
            _c("dropdown-list", {
              attrs: {
                type: "chooser",
                "dropdown-group": "extension-bar-right",
                id: "imageLayerChooser",
                width: "240px",
                hover: "Choose image"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "chooserTitle",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.layerState.activeImageLayer
                                ? _vm.layerState.activeImageLayer.color
                                : undefined
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.layerState.activeImageLayer
                                    ? "Image server: " +
                                        _vm.layerState.activeImageLayer.name
                                    : "Choose image server"
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "listItems",
                    fn: function() {
                      return _vm._l(_vm.imageLayers, function(layer) {
                        return _c(
                          "li",
                          {
                            key: layer.name,
                            class:
                              "nge-dataset-button" +
                              (layer === _vm.activeImageLayer
                                ? " selected"
                                : "")
                          },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.selectImageLayer(layer)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "nge-dataset-button-name" },
                                  [_vm._v(_vm._s(layer.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "nge-dataset-button-description"
                                  },
                                  [_vm._v(_vm._s(layer.description))]
                                )
                              ]
                            )
                          ]
                        )
                      })
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3836432772
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ng-extend-spacer" }),
            _vm._v(" "),
            _c("dropdown-list", {
              attrs: {
                type: "chooser",
                "dropdown-group": "extension-bar-right",
                id: "segmentationLayerChooser",
                width: "240px",
                hover: "Choose dataset"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "chooserTitle",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.layerState.activeSegmentationLayer
                                ? _vm.layerState.activeSegmentationLayer.color
                                : undefined
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.layerState.activeSegmentationLayer
                                    ? "Dataset: " +
                                        _vm.layerState.activeSegmentationLayer
                                          .name
                                    : "Choose Dataset"
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "listItems",
                    fn: function() {
                      return _vm._l(_vm.segmentationLayers, function(layer) {
                        return _c(
                          "li",
                          {
                            key: layer.name,
                            class:
                              "nge-dataset-button" +
                              (layer === _vm.activeSegmentationLayer
                                ? " selected"
                                : "")
                          },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.selectSegmentationLayer(layer)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "nge-dataset-button-name" },
                                  [_vm._v(_vm._s(layer.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "nge-dataset-button-description"
                                  },
                                  [_vm._v(_vm._s(layer.description))]
                                )
                              ]
                            )
                          ]
                        )
                      })
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                982038077
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ng-extend-spacer" }),
            _vm._v(" "),
            _vm.layerState.activeSegmentationLayer &&
            _vm.layerState.activeSegmentationLayer.name === "Sandbox"
              ? [
                  _c("button", {
                    staticClass: "resetSandbox iconBtn",
                    attrs: { title: "Restore default neurons" },
                    on: {
                      click: function($event) {
                        return _vm.resetSandbox()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ng-extend-spacer" })
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.appState.loggedInUser
              ? [
                  _c("dropdown-list", {
                    attrs: {
                      "dropdown-group": "extension-bar-right",
                      id: "loggedInUserDropdown",
                      hover: "User profile"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "buttonTitle",
                          fn: function() {
                            return undefined
                          },
                          proxy: true
                        },
                        {
                          key: "listItems",
                          fn: function() {
                            return [_c("user-card")]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1132374386
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ng-extend-spacer" }),
                  _vm._v(" "),
                  _vm.appState.loggedInUser.admin
                    ? [
                        _c("button", {
                          staticClass: "adminPanel iconBtn",
                          attrs: { title: "Admin dashboard" },
                          on: {
                            click: function($event) {
                              return _vm.showAdminPanel()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "ng-extend-spacer" })
                      ]
                    : _vm._e()
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("button", {
              staticClass: "toggleControls iconBtn",
              class: { open: _vm.appState.viewer.sidebar.open },
              attrs: { title: "Toggle controls" },
              on: {
                click: function($event) {
                  return _vm.appState.toggleSidePanel()
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "ng-extend-spacer" }),
            _vm._v(" "),
            _c("dropdown-list", {
              attrs: {
                "dropdown-group": "extension-bar-right",
                id: "moreActions",
                hover: "More actions"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "buttonTitle",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  },
                  {
                    key: "listItems",
                    fn: function() {
                      return [
                        _vm._l(_vm.appState.actionsMenuItems, function(item) {
                          return _c("li", { key: item.text }, [
                            _c(
                              "button",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.clickAction(item)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.text))]
                            )
                          ])
                        }),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.openSlack()
                                }
                              }
                            },
                            [_vm._v("Slack Forum")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.themesMenu()
                                }
                              }
                            },
                            [_vm._v("Themes")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.toggleBrainMesh()
                                }
                              }
                            },
                            [_vm._v("Toggle Brain Mesh")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.toggleBackgroundColor()
                                }
                              }
                            },
                            [_vm._v("Toggle Background Color")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.screenshot()
                                }
                              }
                            },
                            [_vm._v("Take Screenshot")]
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1053986490
              )
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ng-extend-logo" }, [
      _c("a", { attrs: { href: "https://flywire.ai/", target: "_blank" } }, [
        _c("img", {
          attrs: {
            src: "images/logo-filled.png",
            height: "22",
            title: "FlyWire"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }