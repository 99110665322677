










































import Vue from "vue";

import { layerProxy, storeProxy } from "../state";

export default Vue.extend({
  data: () => {
    return {
      appState: storeProxy,
    }
  },
  computed: {
    inProduction() {
      return layerProxy.activeSegmentationLayer && layerProxy.activeSegmentationLayer!.name!.startsWith("Production");
    },
    inRelease() {
      return layerProxy.activeSegmentationLayer && layerProxy.activeSegmentationLayer!.name!.startsWith("Release");
    }
  }
});
